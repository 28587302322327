import { ReactElement } from "react";

import AutomotiveIcon from "src/icons/AutomotiveIcon";
import AutoInsurance from "src/icons/autoInsurance";
import LifeInsurance from "src/icons/lifeInsurance";
import HealthInsurance from "src/icons/healthInsurance";
import Medicare from "src/icons/medicare";
import B2BIcon from "src/icons/b2b-icon";
import Pos from "src/icons/pos";
import FinancialIcon from "src/icons/FinancialIcon";

import InsuranceIcon from "src/icons/Insurance-icon";

import LifeStyleIcon from "src/icons/life-style-icon";

import SoftwareIcon from "src/icons/SoftwareIcon";
import BusinessPhoneSystems from "src/icons/BusinessPhoneSystems";
import PayrollServices from "src/icons/PayrollServices";
import WorkerCompensationInsurance from "src/icons/workerCompensationInsurance";
import WideFormatPrinters from "src/icons/wideFormatPrinters";
import Forklifting from "src/icons/forklifting";
import ATMMachines from "src/icons/aTMMachines";
import CRMSoftware from "src/icons/CRMSoftware";
import PeoEmployeeLeasing from "src/icons/peoEmployeeLeasing";
import MedicalSoftware from "src/icons/MedicalSoftware";
import EarplugLawyers from "src/icons/earplugLawyers";
import BestFloorCleaningMachines from "src/icons/bestFloorCleaningMachines";
import FleetManagementSystem from "src/icons/fleetManagementSystem";
import InventoryManagement from "src/icons/inventoryManagement";
import OfficeCubiclesAndSystems from "src/icons/officeCubiclesAndSystems";
import StorageContainer from "src/icons/storageContainer";
import CommercialCleaners from "src/icons/commercialCleaners";
import SteelBuildings from "src/icons/steelBuildings";
import TextMassageMarketing from "src/icons/textMassageMarketing";
import CreditCardProcessing from "src/icons/CreditCardProcessing";
import FleetManagement from "src/icons/FleetManagement";
import TaxDebtRelief from "src/icons/taxDebtRelief";
import PostageMeters from "src/icons/postageMeters";
import CommercialCleaningServices from "src/icons/CommercialCleaningServices";
import MealKits from "src/icons/MealKits";
import TeethAligners from "src/icons/TeethAligners";
import OnlineDating from "src/icons/OnlineDating";
import HearingAid from "src/icons/hearingAid";
import Moving from "src/icons/moving";
import CarDealership from "src/icons/CarDealership";
import CarWarranty from "src/icons/carWarranty";
import AutoWarranty from "src/icons/AutoWarranty";
import AutoTransportation from "src/icons/AutoTransportation";
import AutoLoans from "src/icons/AutoLoans";
import CreditRepair from "src/icons/CreditRepair";
import HostingServices from "src/icons/hostingServices";
import AccountingSoftware from "src/icons/accountingSoftware";
import PasswordManagers from "src/icons/PasswordManagers";
import InternetServiceProviders from "src/icons/InternetServiceProviders";
import WebsiteBuilders from "src/icons/websiteBuilders";
import CMS from "src/icons/cMS";
import Antivirus from "src/icons/antivirus";
import EnterpriseContent from "src/icons/enterpriseContent";
import VPN from "src/icons/vPN";
import DomainRegistrars from "src/icons/DomainRegistrars";
import Legal from "src/icons/legal";
import DigitalCopiers from "src/icons/digitalCopiers";
const iconsLookup: { [key: string]: ReactElement } = {
    /******************************** Insurance *******************************/

    Insurance: <InsuranceIcon />,
    auto: <AutoInsurance />,
    life: <LifeInsurance />,
    health: <HealthInsurance />,
    medicare: <Medicare />,
    /******************************** B2B *******************************/

    b2b: <B2BIcon />,
    pos: <Pos />,
    "point-of-sale": <Pos />,
    "business-phone-systems": <BusinessPhoneSystems />,
    payroll: <PayrollServices />,
    "worker-compensation-insurance": <WorkerCompensationInsurance />,
    "wide-format-printers": <WideFormatPrinters />,
    "atm-machines": <ATMMachines />,
    forklift: <Forklifting />,
    crm: <CRMSoftware />,
    peo: <PeoEmployeeLeasing />,
    "medical-software": <MedicalSoftware />,
    "military-earplugs": <EarplugLawyers />,
    "floor-cleaning-machines": <BestFloorCleaningMachines />,
    fleet: <FleetManagementSystem />,
    "Inventory mangement": <InventoryManagement />,
    cubicles: <OfficeCubiclesAndSystems />,
    "storage-container": <StorageContainer />,
    "Commertial Cleaners": <CommercialCleaners />,
    "steel-buildings": <SteelBuildings />,
    "text-message-marketing": <TextMassageMarketing />,
    "credit-card-processing": <CreditCardProcessing />,
    "fleet-management": <FleetManagement />,
    "Tax Debt Relief": <TaxDebtRelief />,
    "debt-relief": <TaxDebtRelief />,
    "tax-debt-relief": <TaxDebtRelief />,
    "postage-meters": <PostageMeters />,
    "commercial-cleaning-services": <CommercialCleaningServices />,
    "digital-copier": <DigitalCopiers />,
    /******************************** LifeStyle *******************************/

    lifestyle: <LifeStyleIcon />,
    "meal-kit": <MealKits />,
    "online-dating": <OnlineDating />,
    "hearing-aids": <HearingAid />,
    "moving-companies": <Moving />,
    "teeth-aligners": <TeethAligners />,
    /******************************** Automotive *******************************/

    automotive: <AutomotiveIcon />,
    "car-dealership": <CarDealership />,
    "Car Warranty": <CarWarranty />,
    "auto-transportation": <AutoTransportation />,
    "auto-warranty": <AutoWarranty />,
    /********************************  Software *******************************/

    software: <SoftwareIcon />,
    hosting: <HostingServices />,
    vpn: <VPN />,
    "enterprise-content-management": <EnterpriseContent />,
    antivirus: <Antivirus />,
    cms: <CMS />,
    "domain-registrars": <DomainRegistrars />,
    "website-builder": <WebsiteBuilders />,
    "internet-service-providers": <InternetServiceProviders />,
    "password-managers": <PasswordManagers />,
    "accounting-software": <AccountingSoftware />,
    /******************************** Financial *******************************/
    financial: <FinancialIcon />,
    "auto-loans": <AutoLoans />,
    "credit-repair": <CreditRepair />,
    legal: <Legal />,
};

export function iconsLookupsHelper(key: string): ReactElement {
    return iconsLookup[key];
}
